function stickyElement() {
    
    // Get elements
    const element = document.getElementById('header');

    // Get height
    const scrollHeight = window.scrollY;

    if (scrollHeight > 1) {
        element.classList.add('sticky-element');
    } else {
        element.classList.remove('sticky-element');
    }
}

// Init sticky
stickyElement();

// Event scroll
window.addEventListener('scroll', stickyElement);