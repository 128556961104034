/* ==========================================================================
    Slide toggle
========================================================================== */
function slideToggle(element, currentHeight = 'maxHeight') {
    if (element.style[currentHeight]) {
        element.style[currentHeight] = null;
    } else {
        element.style[currentHeight] = element.scrollHeight + 'px';
    }
}

/* ==========================================================================
    Fade toggle
========================================================================== */
function fadeToggle(contents, index) {
    contents.forEach((content, contentIndex) => {
        content.style.display = 'none';

        if (index === contentIndex) {
            content.style.display = 'block';
        }
    });
}

/* ==========================================================================
    Slide down
========================================================================== */
function slideDown(element) {
    element.style.maxHeight = element.scrollHeight + 'px';
}

/* ==========================================================================
    Slide up
========================================================================== */
function slideUp(element) {
    element.style.maxHeight = null;
}

/* ==========================================================================
    Remove some class from list
========================================================================== */
function removeSomeClass(elements, className) {
    elements.forEach((elem) => {
        if (elem.classList.contains(className)) {
            elem.classList.remove(className);
        }
    });
}

/* ==========================================================================
    XMLHttpRequest
========================================================================== */
function request(url, callback, async) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, async);
    xhr.onload = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                callback(JSON.parse(xhr.responseText));
            } else {
                console.error(xhr.statusText);
            }
        }
    };
    xhr.onerror = () => {
        console.error(xhr.statusText);
    };
    xhr.send(null);
}

const ApiRoot = 'http://polygon-wordpress.denysutsekha.com';

// export { slideToggle, slideDown, slideUp, fadeToggle, removeSomeClass, request, ApiRoot };