import { slideDown, slideUp, removeSomeClass } from './../Helpers';

const humburger = document.getElementById('humburger');
const header = document.getElementById('header');
const menu = document.getElementById('menu');

humburger.addEventListener('click', () => {

    if (humburger.classList.contains('is-active')) {
        humburger.classList.remove('is-active');
        menu.classList.remove('is-active');
    } else {
        humburger.classList.add('is-active');
        menu.classList.add('is-active');
    }
});

/* ==========================================================================
    Mobile menu
========================================================================== */
const submenu = document.querySelectorAll('.submenu');
const menuLinks = document.querySelectorAll('.menu > ul > li > a');

// Check window width
if (window.innerWidth <= 992) {

    // Event:click
    menuLinks.forEach(link => {
        link.addEventListener('click', (event) => {

            if (event.target.classList.contains('is-active')) {
                event.target.classList.remove('is-active');

                if (event.target.nextElementSibling) {
                    slideUp(event.target.nextElementSibling);
                }
            } else {
                removeSomeClass(menuLinks, 'is-active');
                submenu.forEach(item => {
                    slideUp(item);
                });
                event.target.classList.toggle('is-active');

                if (event.target.nextElementSibling) {
                    slideDown(event.target.nextElementSibling);
                }
            }
        });
    });

    // Add arrow to submenu items
    document.querySelectorAll('.menu > ul > li').forEach(item => {
        if (item.querySelector('.submenu')) {
            item.classList.add('has-submenu');
        }
    });
}